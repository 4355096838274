import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect  } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  state = {
    loggedIn: false,
  };

  componentWillMount(){
    const loginStatus = localStorage.getItem('loginStatus');
    if(loginStatus){
      this.state.loggedIn = true;
    }
    else{
      this.state.loggedIn = false;
    }
  }

  //BrowserRouter
  render() {
    //we want to check if we put our code inside will mount here what will happen
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page"  render= {props=> { return !this.state.loggedIn ? <Login {...props}/> : <Redirect to="/agenda" />;  } }  />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => { return this.state.loggedIn ? <DefaultLayout {...props}/> : <Redirect to="/login" />; } } />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
